import _ from 'lodash'
import { useLocalStorage } from '@vueuse/core'
import {
    createConversation,
    sortConversation,
    createInform
} from '@/utils/handleSomeData'
import Message from './message'
import defaultGroupAvatarUrl from '@/assets/images/avatar/qunavatar.png'
import { EaseChatClient } from '@/IM/initwebsdk'
import { informType, messageType } from '@/constant'
import { useRouter, useRoute } from 'vue-router'
import { sessionListList } from '@/api/custom'
/* route */
const route = useRoute()
/* router */
const router = useRouter()
const { INFORM_FROM } = informType
const { CHAT_TYPE } = messageType
const Conversation = {
    state: {
        informDetail: [],
        conversationListData: {},//所有会话数据对象
        conversationListArr: [],//所有会话数据数组
        topLength: 0,//置顶会话的长度
    },
    mutations: {
        //初始化会话列表的数据（根据登陆的id取其对应的会话数据）
        INIT_CONVERSATION_STATE: (state, temp) => {
            // //console.log("%c Line:27 🍉 temp", "color:#b03734", temp);
            const storageId = EaseChatClient.user
            state.informDetail = useLocalStorage(
                `EASEIM_${storageId}_INFORM`,
                []
            )
            const sortedData = sortConversation(
                _.cloneDeep(temp)
            )
            state.conversationListData = sortedData
            // console.log("%c Line:40 🥛 state.conversationListData", "color:#b03734", state.conversationListData);
            // state.conversationListData = useLocalStorage(
            //     `EASEIM_${storageId}_conversationList`,
            //     {}
            // )
            // //console.log("%c Line:62 🌮 会话列表>>>>>>>>>>>>state.conversationListData", "color:#33a5ff", state.conversationListData);
        },
        //清空系统通知
        CLEAR_INFORM_LIST: (state) => {
            state.informDetail = []
        },
        //更新系统通知
        UPDATE_INFORM_LIST: (state, informBody) => {
            const toBeUpdateInform = _.assign([], state.informDetail)
            // let _index = toBeUpdateInform.findIndex(
            //   (v) => v.from === informBody.from
            // );
            // if (_index === -1) {
            //   toBeUpdateInform.unshift(informBody);
            // } else {
            //   toBeUpdateInform.splice(_index, 1);
            //   toBeUpdateInform.unshift(informBody);
            // }
            toBeUpdateInform.unshift(informBody)
            state.informDetail = toBeUpdateInform
        },
        //更新已有会话
        UPDATE_CONVERSATION_LIST: (state, payload) => {
            //console.log('>>>>>>>开始更新会话', payload)
            const sortedData = sortConversation(
                _.assign(_.cloneDeep(state.conversationListData), payload)
            )
            state.conversationListData = sortedData

        },
        //删除某条会话
        DELETE_ONE_CONVERSATION: (state, key) => {
            //console.log('>>>>>>>执行删除会话操作', key)
            const toUpdateConversation = _.assign(
                {},
                state.conversationListData
            )
            if (toUpdateConversation[key]) {
                delete toUpdateConversation[key]
            }
            //console.log('删除后toUpdateConversation', toUpdateConversation)
            state.conversationListData = _.assign({}, toUpdateConversation)
        },
        //清除会话未读状态
        CLEAR_UNREAD_NUM: (state, key) => {
            state.conversationListData[key].unreadMessageNum = 0
            let localUnread = window.localStorage.getItem('localUnread')
            if (localUnread) {
                localUnread = JSON.parse(localUnread)
            } else {
                localUnread = {}
            }
            if (key in localUnread) {
                delete localUnread[key]
            }
            window.localStorage.setItem('localUnread', JSON.stringify(localUnread))
        },
        //清除信息卡片未读
        CLEAR_UNTREATED_STATUS: (state, index) => {
            //console.log('>>>>>执行清除卡片未读', index)
            state.informDetail[index].untreated = 0
        },
        //更改卡片消息的按钮状态
        UPDATE_INFORM_BTNSTATUS: (state, { index: index, btnStatus }) => {
            //console.log('>>>>触发了按钮更新状态', index, btnStatus)
            state.informDetail[index].operationStatus = btnStatus
        }
    },
    actions: {
        // 初始化会话列表
        getSessionList: async ({ commit }, userId) => {
            // console.log("%c Line:115 🍞开始获取会话列表 userId", "color:#ed9ec7",new Date());
            let tempObj = {}
            try {
                let obj = {
                    formUser: userId,
                    pageNum: 1,
                    pageSize: 50
                }
                let { rows } = await sessionListList(obj)
                // console.log("%c Line:124 🍉获取到会话>>>>>>>", "color:#465975",new Date());
                let localUnread = window.localStorage.getItem('localUnread')
                if(localUnread){
                  localUnread = JSON.parse(localUnread)
                //   console.log("%c Line:128 🍆 localUnread", "color:#4fff4B", localUnread);
                }else{
                  localUnread = {}
                }
       
                if (rows && rows.length) {
                    for (let i = 0; i < rows.length; i++) {
                        let item = rows[i]
                        let msgtxt = item.type == 'text' ? item.content : ''
                        let type = 'txt'
                        if (item.type == 'custom') {
                            type = 'custom'
                            if (item.customType == 'image') {
                                msgtxt = '[图片]'
                            } else if (item.customType == 'video') {
                                msgtxt = '[视频]'
                            } else if (item.customType == 'goods') {
                                msgtxt = '[商品]'
                            } else if (item.customType == 'audio') {
                                msgtxt = '[语音]'
                            } else {
                                msgtxt = '[其他]'
                            }
                        }

                        if (item.msgType == 2) {
                            // 群组
                            tempObj[item.sessionId] = {
                                "sessionId": item.sessionId,
                                "isTop": item.isTop,
                                "conversationType": "groupChat",
                                "conversationKey": item.sessionId,
                                "conversationInfo": {
                                    "name": item.groupName,
                                    "avatarUrl": defaultGroupAvatarUrl
                                },
                                "fromInfo": {
                                    "fromId": item.formUser,
                                    "fromName": ""
                                },
                                "targetId": item.sessionId,
                                "unreadMessageNum":localUnread[item.sessionId]&&localUnread[item.sessionId].unreadMessageNum?localUnread[item.sessionId].unreadMessageNum:0,
                                "latestMessage": {
                                    "msg": msgtxt,
                                    "type": type,
                                    "ext": {
                                        "timeout": 1
                                    }
                                },
                                "latestMessageId": item.messageId,
                                "latestSendTime": item.updateTime ? new Date(item.updateTime).getTime() : new Date(item.createTime).getTime()

                            }
                        } else {
                            // 单聊
                            let targetId = item.formUser == userId ? item.toUser : item.formUser
                            //  //console.log("%c Line:154 🍇 targetId", "color:#e41a6a", targetId);
                            tempObj[targetId] = {
                                "sessionId": item.sessionId,
                                "isTop": item.isTop,
                                "conversationType": "singleChat",
                                "conversationKey": targetId,
                                "conversationInfo": {
                                    "name": item.formUser == userId ? item.toNickName : item.fromNickName,
                                    "avatarUrl": item.formUser == userId ? item.toAvatar : item.fromAvatar
                                },
                                "fromInfo": {
                                    "fromId": item.formUser,
                                    "fromName": item.fromNickName
                                },
                                "targetId": targetId,
                                "unreadMessageNum": localUnread[targetId]&&localUnread[targetId].unreadMessageNum?localUnread[targetId].unreadMessageNum:0,
                                "latestMessage": {
                                    "msg": msgtxt,
                                    "type": type,
                                    "ext": {
                                        "timeout": 1
                                    }
                                },
                                "latestMessageId": item.messageId,
                                "latestSendTime": item.updateTime ? new Date(item.updateTime).getTime() : new Date(item.createTime).getTime()

                            }
                        }
                    }
                }
            }
            catch (error) {
            }
            commit(
                'INIT_CONVERSATION_STATE',
                tempObj
            )

        },
        //添加新系统通知
        createNewInform: ({ dispatch, commit }, params) => {
            const { fromType, informContent } = params
            //console.log('>>>>>>>>>createNewInform', fromType, informContent)
            const result = createInform(fromType, informContent)
            commit('UPDATE_INFORM_LIST', result)

            //部分事件需要调用接口更新本地信息或者增加消息内系统通知
            if (fromType === INFORM_FROM.FRIEND) {
                const informMsg = {
                    from: informContent.from,
                    to: informContent.to,
                    chatType: CHAT_TYPE.SINGLE
                }
                switch (informContent.type) {
                    case 'other_person_agree':
                        {
                            //他人同意更新好友列表
                          
                            dispatch('fetchFriendList')
                        }

                        break
                    case 'unsubscribed':
                        {
                            informMsg.msg = '你俩的友尽了，可重新发起好友申请'
                            dispatch('createInformMessage', informMsg)
                        }
                        break
                    case 'subscribed':
                        {
                            informMsg.msg = '已成为你的好友,开始聊天吧'
                            dispatch('createInformMessage', informMsg)
                        }
                        break
                    default:
                        break
                }
            }
            if (fromType === INFORM_FROM.GROUP) {
                const informMsg = {
                    from: informContent.from,
                    to: informContent.id,
                    chatType: CHAT_TYPE.GROUP
                }
                switch (informContent.operation) {
                    case 'memberPresence': //入群通知
                        {
                            // informContent
                            //console.log("%c Line:245 🥚 入群通知informContent", "color:#ea7e5c", informContent);
                            commit('UPDATE_GROUP_INFOS', {
                                groupId: informContent.id,
                                type: 'addAffiliationsCount'
                            })
                            dispatch('fetchGoupsMember', informContent.id)
                            informMsg.msg = `${informContent.from}加入了群组`
                            dispatch('createInformMessage', informMsg)
                            // dispatch('getGroupPersons', {
                            //   groupId:informContent.id,
                            //   fromHxId:EaseChatClient.user
                            // })
                        }
                        break
                    case 'memberAbsence':
                        {
                            //退群通知
                            commit('UPDATE_GROUP_INFOS', {
                                groupId: informContent.id,
                                type: 'delAffiliationsCount'
                            })
                            dispatch('fetchGoupsMember', informContent.id)
                            informMsg.msg = `${informContent.from}退出了群组`
                            dispatch('createInformMessage', informMsg)
                            // dispatch('getGroupPersons', {
                            //   groupId:informContent.id,
                            //   fromHxId:EaseChatClient.user
                            // })
                        }
                        break
                    case 'updateAnnouncement':
                        {
                            //更新群公告
                            dispatch('fetchAnnounment', informContent.id)
                            informMsg.msg = `${informContent.from}更新了群组公告，去看看更新的什么吧~`
                            dispatch('createInformMessage', informMsg)
                        }
                        break
                    case 'setAdmin':
                        {
                            dispatch('fetchGoupsAdmin', informContent.id)
                            informMsg.msg = `${informContent.from}设定${informContent.to}为管理员~`
                            dispatch('createInformMessage', informMsg)
                        }
                        break
                    case 'removeAdmin':
                        {
                            dispatch('fetchGoupsAdmin', informContent.id)
                            informMsg.msg = `${informContent.from}移除了${informContent.to}的管理员身份~`
                            dispatch('createInformMessage', informMsg)
                        }
                        break
                    case 'muteMember':
                        {
                            informMsg.msg = `${informContent.from}禁言了${informContent.to ? informContent.to : '你'
                                }~`
                            dispatch('createInformMessage', informMsg)
                        }
                        break
                    case 'unmuteMember':
                        {
                            informMsg.msg = `${informContent.from}取消了${informContent.to ? informContent.to : '你'
                                }的禁言~`
                            dispatch('createInformMessage', informMsg)
                        }
                        break
                    case 'removeMember':
                        {
                            // informMsg.msg = `${informContent.from}将你移出了群组${informContent.id}~`
                            // dispatch('createInformMessage', informMsg)
                            //执行删除会话
                            commit('DELETE_ONE_CONVERSATION', informContent.id)
                            //从群组列表中移除
                            commit('UPDATE_GROUP_LIST', {
                                type: 'deleteFromList',
                                groupId: informContent.id
                            })
                        }
                        break
                    case 'destroy':
                        {
                            informMsg.msg = `${informContent.from}解散了该群~`
                            dispatch('createInformMessage', informMsg)
                            setTimeout(() => {
                                dispatch('fetchGroupList', {
                                    // pageNum: 1,
                                    // pageSize: 2000,
                                    userName: EaseChatClient.user
                                })
                                // 删除本地会话
                                // 成功，删除本地会话
                                commit(
                                    'DELETE_ONE_CONVERSATION',
                                    informContent.id
                                )
                                // //如果删除的itemKey与当前的message会话页的id一致则跳转至会话默认页。
                                if (
                                    route?.query?.id &&
                                    route.query.id === informContent.id
                                ) {
                                    router.push('/chat/conversation')
                                }
                            }, 300)
                        }
                        break
                    case 'updateInfo':
                        {
                            informMsg.msg = `${informContent.from}更新了群组详情~`
                            dispatch('createInformMessage', informMsg)
                            dispatch('getAssignGroupDetail', informContent.id)
                        }
                        break
                    case 'acceptRequest':
                        {
                            setTimeout(() => {
                                dispatch('fetchGroupList', {
                                    // pageNum: 1,
                                    // pageSize: 2000,
                                    userName: EaseChatClient.user
                                })
                            }, 300)
                        }
                        break
                    case 'changeOwner':
                        {
                            //更换群主 informContent
                            //console.log("%c Line:364 🍊更换群主 informContent", "color:#ffdd4d", informContent);
                            // dispatch('fetchAnnounment', informContent.id)
                            informMsg.msg = `${informContent.from}变更了群主~`
                            dispatch('createInformMessage', informMsg)
                            dispatch('fetchGoupsMember', informContent.id)
                            // dispatch('getGroupPersons', {
                            //   groupId:informContent.id,
                            //   fromHxId:EaseChatClient.user
                            // })
                        }
                        break
                    default:
                        break
                }
            }
            //memberPresence 群成员加入群组需要进行群组人数+1操作。
            // commit('UPDATE_GROUP_INFOS',{})
        },

        //收集会话依赖数据
        gatherConversation: async ({ commit }, key) => {
            const corresMessage = _.cloneDeep(Message.state.messageList[key])
            const res = await createConversation(corresMessage)
            commit('UPDATE_CONVERSATION_LIST', res)
        }
    }
}
export default Conversation
